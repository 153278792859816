import { Expose, Transform } from 'class-transformer';
import { StoreCmsPropertyDto } from './store-cms-property.dto';
import { Pipes } from '../../utils';

export class StoreCmsPropertiesDto {
  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return data.properties.map(property => Pipes.transform(StoreCmsPropertyDto, property))
  })
  properties!: StoreCmsPropertyDto[];
}
