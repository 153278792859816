import React from 'react';
import { StoreDto } from './dto/store.dto';
import { CartDto } from './dto/cart.dto';

export interface IAppContext {
  store: StoreDto;
  setStore?: Function;
  user?: json;
  setUser?: Function;
  cart?: CartDto;
  setCart?: Function;
}

const AppContext = React.createContext({} as IAppContext);

export { AppContext };
