import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { AppContext, IAppContext } from '../../context';

const Hero = () => {
  const {store} = useContext(AppContext) as IAppContext;
  return (
    <>
      <header className="py-5 d-none d-lg-block"
              style={{
                backgroundColor: store['heroBgColor'],
                backgroundImage: `url('${isMobile ? store['heroBgMediaMobile'] : store['heroBgMedia']}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}>
        <div className="container px-4 px-lg-5 my-5">
          <div className="text-center text-white">
            <h1 className="display-4 fw-bolder">{store && store['title']}</h1>
            <p className="lead fw-normal text-white-50 mb-0">{store &&
              store['subTitle']}</p>
          </div>
        </div>
      </header>
    </>
  );
};

export default Hero;
