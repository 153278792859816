import React, { useContext, useEffect, useState } from 'react';
import { IProps } from '../../types';
import { AppContext, IAppContext } from '../../context';
import { Link } from 'react-router-dom';
import { Pages } from '../../utils/pages';
import { TrySync } from '../../utils';

interface IMainNavbar extends IProps {
  avatarImage?: string;
  withCartDrawer?: boolean;
  withShopMenu?: boolean;
}

const MainNavbar = ({
  className,
  avatarImage,
  withCartDrawer = true,
  withShopMenu = false,
}: IMainNavbar) => {
  const {store, cart} = useContext(AppContext) as IAppContext;
  const [isBrandMedia, setIsBrandMedia] = useState(false);
  const [navbarClassName, setNavbarClassName] = useState(
    'navbar navbar-expand-lg navbar-light bg-light min-h-60--px sticky-top');
  const [navbarBrandClassName, setNavbarBrandClassName] = useState(
    'navbar-brand');

  useEffect(() => {
    setIsBrandMedia(!!store['navbarBrandMedia']);
    if (!!store['navbarBrandMedia']) {
      setNavbarClassName(`${navbarClassName} p-0`);
      setNavbarBrandClassName(`${navbarBrandClassName} p-0 d-flex`);
    }
  }, [store]);

  const shopMenu = TrySync(() => {
    if (!withShopMenu) {
      return <></>;
    }
    return (<li className="nav-item dropdown">
      <a className="nav-link dropdown-toggle"
         id="navbarDropdown"
         href="#"
         role="button"
         data-bs-toggle="dropdown"
         aria-expanded="false">Shop</a>
      <ul className="dropdown-menu"
          aria-labelledby="navbarDropdown">
        <li><a className="dropdown-item"
               href="#!">All Products</a></li>
        <li>
          <hr className="dropdown-divider"/>
        </li>
        <li><a className="dropdown-item"
               href="#!">Popular Items</a></li>
        <li><a className="dropdown-item"
               href="#!">New Arrivals</a></li>
      </ul>
    </li>);
  });

  const cartDrawerButton = TrySync(() => {
    if (!withCartDrawer) {
      return <></>;
    }
    return (<button className="btn btn-outline-dark"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight">
      <i className="bi-cart-fill me-1"></i>
      Cart
      <span className="badge bg-dark text-white ms-1 rounded-pill">{cart?.items?.length ??
        0}</span>
    </button>);
  });

  return (
    <>
      <div className={'sticky-top'}>
        {store.isSandbox ? <div className={'bg-warning text-center w-auto'}>This is
          Sandbox mode</div> : <></>}
        <nav className={navbarClassName}>
          <div className="container px-4 px-lg-5">
            <Link className={navbarBrandClassName}
                  to={Pages.home()}>{
              isBrandMedia ? <img className={'align-middle'}
                                  src={store['navbarBrandMedia']}
                                  width={60}/> : store['navbarBrandName']
            }</Link>
            <div className="d-flex justify-content-between"
                 id="navbarSupportedContent">
              <ul className="navbar-nav me-auto- mb-2 mb-lg-0 ms-lg-4 d-none">
                <li className="nav-item"><Link className="nav-link active"
                                               aria-current="page"
                                               to={Pages.home()}>Home</Link>
                </li>
                <li className="nav-item"><Link className="nav-link"
                                               to={Pages.about()}>About</Link>
                </li>
                {shopMenu}
              </ul>
              {cartDrawerButton}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export { MainNavbar };
