import { Expose } from 'class-transformer';
import { IsEmail, IsNotEmpty, IsOptional, IsString } from 'class-validator';

export class BillingAddressDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  firstName!: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @Expose()
  @IsString()
  @IsOptional()
  addressLine1?: string;

  @Expose()
  @IsString()
  @IsOptional()
  addressLine2?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  country!: string;

  @Expose()
  @IsString()
  @IsOptional()
  state?: string;

  @Expose()
  @IsString()
  @IsOptional()
  zipCode?: string;
}
