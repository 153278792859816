import { Expose, Type } from 'class-transformer';
import { StoreCmsMediaDto } from './store-cms-media.dto';

export class StoreCmsPropertyDto {
  @Expose()
  property!: string;

  @Expose()
  value?: string;

  @Expose()
  media?: StoreCmsMediaDto;
}
