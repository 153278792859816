/**
 * Class with cache utilities
 */
export class CacheUtil {
  /**
   * Function which sets or retrieves a key in the localStorage if supports
   * @param key
   * @param value
   * @returns {string|any}
   * @throws Error
   */
  static cache (key, value = undefined) {
    if (!key) {
      throw new Error('Key arg is required')
    }
    if (!window.localStorage) {
      throw new Error('Local Storage is not supported in this browser')
    }
    if (!value) {
      const stored = window.localStorage.getItem(key)
      try {
        return JSON.parse(stored)
      } catch (e) {
        return stored
      }
    } else {
      window.localStorage.setItem(key,
        typeof value === 'object' ? JSON.stringify(value) : value)
      // recursive
      return CacheUtil.cache(key)
    }
  }

  /**
   * Function which removes an item of cache
   * @param key
   * @returns void
   * @throws Error
   */
  static removeCache (key) {
    if (!key) {
      throw new Error('Key arg is required')
    }
    if (!window.localStorage) {
      throw new Error('Local Storage is not supported in this browser')
    }
    window.localStorage.removeItem(key)
  }
}
