import React from 'react';
import MainLayout from '../../layouts/main.layout';
import HomeProductsContainer
  from '../../components/products/home/home-products-container';

const HomePage = () => {
  return (
    <MainLayout>
      <HomeProductsContainer />
    </MainLayout>
  );
};

export { HomePage as default };
