import { useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import { ShippingMethodDto } from './dto/shipping-method.dto';

export default function useListShippingMethods() {
  const [shippingMethods, setShippingMethods] = useState(
    [] as Array<ShippingMethodDto>);
  useEffect(() => {
    RequestUtil.get('/shipping-methods', {}, () => ShippingMethodDto).
      then(response => {
        setShippingMethods(response as Array<ShippingMethodDto>);
      }).
      catch(errors => console.error('Error: useListShippingMethods'));
  }, []);
  return shippingMethods;
};