import {plainToInstance} from 'class-transformer';
import {
  ClassConstructor,
  ClassTransformOptions,
} from 'class-transformer/types/interfaces';
import {parse, stringify} from 'flatted';
import { validateOrReject, ValidatorOptions } from 'class-validator';

const validationPipe = {
  whitelist: true,
  transform: true,
  forbidNonWhitelisted: true,
  transformOptions: {
    enableImplicitConversion: true,
  },
};

export class Pipes {
  static validationPipe() {
    return validationPipe;
  }

  static transform<S, T>(
    cls: ClassConstructor<T>,
    obj: S | S[],
    options: ClassTransformOptions = {
      enableCircularCheck: true,
      excludeExtraneousValues: true,
    },
  ): T | T[] {
    if (Array.isArray(obj)) {
      return obj.map(o => plainToInstance(cls, parse(stringify(o)), options));
    }
    return plainToInstance(cls, parse(stringify(obj)), options);
  }

  static validation<S, T>(
    cls: ClassConstructor<T>,
    obj: S,
    options?: ValidatorOptions
  ) {
    // @ts-ignore
    return validateOrReject(Pipes.transform(cls, obj), options);
  } 
}
