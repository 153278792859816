import { Expose } from 'class-transformer';

export class ProductMediaDto {
  @Expose()
  fileName!: string;

  @Expose()
  mediaType!: string;

  @Expose()
  base64!: string;

  @Expose()
  isMainImage!: boolean;
}
