import { Expose, Transform, Type } from 'class-transformer';
import { Pipes } from '../../utils';
import { CartItemDto } from './cart-item-dto';
import { CartSummaryDto } from './cart-summary.dto';
import { BillingAddressDto } from './billing-address.dto';
import {
  PaymentGatewayDto
} from '../../hooks/payment-gateways/dto/payment-gateway.dto';
import {
  ShippingMethodDto
} from '../../hooks/shipping-methods/dto/shipping-method.dto';

export class CartDto {
  @Expose()
  uuid!: string;

  @Expose()
  @Transform(({obj: data}: { obj: json }) => {
    return data.items?.map(
      (item: { product: CartItemDto, qty: number }) => {
        const newItemData = {...item.product, qty: item.qty};
        return Pipes.transform(CartItemDto, newItemData);
      });
  })
  items!: CartItemDto[];
  
  @Expose()
  @Transform(({obj: data}: { obj: json }) => {
    return Pipes.transform(CartSummaryDto, data.summary ?? {});
  })
  summary!: CartSummaryDto;
  
  @Expose()
  billingAddress?: BillingAddressDto;

  @Expose()
  shipping?: ShippingMethodDto;

  @Expose()
  payment?: PaymentGatewayDto;
}
