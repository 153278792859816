import React from 'react';
import MainLayout from '../../layouts/main.layout';
import MainBody from '../../components/main/main-body';
import NotFoundLayout from '../../layouts/not-found.layout';

const NotFoundPage = () => {
  return (
    <NotFoundLayout>
      <MainBody>
        <div className="h-100">
          <h1 className="text-center p-5">
            404
          </h1>
        </div>
      </MainBody>
    </NotFoundLayout>
  );
};

export { NotFoundPage as default };
