import { DependencyList, useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import { CountryDto } from './dto/country.dto';
import { CountryStateDto } from './dto/country-state.dto';

export default function useListCountryStates(
  country: CountryDto, deps: DependencyList = []) {
  const [states, setStates] = useState([] as Array<CountryStateDto>);
  useEffect(() => {
    RequestUtil.get(`/countries/${country.isoCode3}/states`, {},
      () => CountryStateDto).then(response => {
      setStates(response as Array<CountryStateDto>);
    }).catch(errors => console.error('Error: useListCountryStates'));
  }, deps);
  return states;
};