import React, { useContext, useEffect, useState } from 'react';
import { AppContext, IAppContext } from '../context';
import { MainContainer } from '../components/main/main-container';
import { MainNavbar } from '../components/main/main-navbar';
import { IProps } from '../types';
import { MainFooter } from '../components/main/main-footer';
import { UIToast } from '../components/toast/toast.ui';
import { PubSubEnum } from '../enums/pub-sub.enum';

const CheckoutLayout = ({children}: IProps) => {
  const {user} = useContext(AppContext) as IAppContext;
  const [avatarImage, setAvatarImage] = useState();

  useEffect(() => {
    setAvatarImage(user?.avatarMedia?.base64 ?? '');
  }, [user]);

  return (
    <div className={'d-flex flex-column h-100'}>
      <UIToast topic={Object.values(JSON.parse(JSON.stringify(PubSubEnum)))}></UIToast>
      <MainNavbar avatarImage={avatarImage}
                  withCartDrawer={false}/>
      <header>
      </header>
      <main className="bg-light">
        <MainContainer>
          {children}
        </MainContainer>
      </main>
      {/*<MainFooter/>*/}
    </div>

  );
};

export default CheckoutLayout;
