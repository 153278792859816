import React from 'react';
import MainBody from '../../components/main/main-body';

const OopsPage = () => {
  return (
    <MainBody>
      <div className="h-100">
        <h1 className="text-center p-5">
          Oops! something was wrong!
        </h1>
      </div>
    </MainBody>
  );
};

export { OopsPage as default };
