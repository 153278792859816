/**
 * Util function which tries the closure function.
 * @param {Function} closure the closure function.
 * @param {boolean} showLog if true, then shows the log message.
 * @returns {T} T
 */
export const Try = async <T>(closure: () => T, showLog = false) => {
  try {
    return await closure();
  } catch (error) {
    if (showLog) {
      console.error(error);
    }
    return undefined;
  }
};

/**
 * Util function which tries the closure function.
 * @param {Function} closure the closure function.
 * @param {boolean} showLog if true, then shows the log message.
 * @returns {T} T
 */
export const TrySync = <T>(closure: () => T, showLog = false) => {
  try {
    return closure();
  } catch (error) {
    if (showLog) {
      console.error(error);
    }
    return undefined;
  }
};
