import React from 'react';
import {
  PaymentGatewayDto
} from '../../hooks/payment-gateways/dto/payment-gateway.dto';

export interface ICheckoutPageContext {
  listPaymentGateways?: PaymentGatewayDto[];
  setListPaymentGateways?: Function;
  showThankYouMessage: boolean;
  setShowThankYouMessage?: Function;
}

const CheckoutPageContext = React.createContext(
  {} as ICheckoutPageContext);

export { CheckoutPageContext };
