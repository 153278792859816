import { Expose, Type } from 'class-transformer';
import { PaymentGatewayPropertyDto } from './payment-gateway-property.dto';

export class PaymentGatewayDto {
  @Expose()
  uuid?: string;

  @Expose()
  name?: string;

  @Expose()
  code?: string;

  @Expose()
  @Type(() => PaymentGatewayPropertyDto)
  properties?: PaymentGatewayPropertyDto[];
}
