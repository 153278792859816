import React from 'react';
import { IProps } from '../../types';

const MainBody = ({className, children}: IProps) => {
  return (
    <>
      <div
        className={className ? className : 'position-relative p-0 overflow-auto'}>
        {children}
      </div>
    </>
  );
};

export { MainBody as default };
