import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IProps } from '../../../types';
import { CartItemDto } from '../../../context/dto';
import { CartUtils } from '../cart-utils';
import { ProductDto } from '../../products/dto/product-dto';
import { AppContext, IAppContext } from '../../../context';
import Spinner from '../../spinner/spinner';
import { TrySync } from '../../../utils';

interface IItem extends IProps {
  item?: CartItemDto;
}

const CartItemDrawer = ({item}: IItem) => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const [itemImage, setItemImage] = useState('');
  const [qty, setQty] = useState(0);
  const [submitQtyToCart, setSubmitQtyToCart] = useState(false);

  useEffect(() => {
    const [image] = item?.images?.filter(media => media.isMainImage) ?? [];
    setItemImage(
      image?.base64 ?? 'https://dummyimage.com/650x500/dee2e6/6c757d.jpg');
  }, [item]);

  useEffect(() => {
    setQty(item?.qty ?? 0);
  }, [cart]);

  const onClickAdd = useCallback(() => {
    setSubmitQtyToCart(true);
    CartUtils.addToCart(cart, item as ProductDto, 1, setCart).then((response) => {
      setQty(qty + 1);
    }).finally(() => {
      setSubmitQtyToCart(false);
    });
  }, [qty]);

  const onClickSub = useCallback(() => {
    setSubmitQtyToCart(true);
    CartUtils.addToCart(cart, item as ProductDto, -1, setCart).then(() => {
      setQty(qty - 1);
    }).finally(() => {
      setSubmitQtyToCart(false);
    });
  }, [qty]);

  const onChangeQty = (evt: any) => {
    // TODO: change qty via api
    setQty(evt.target.value);
  };

  return (
    <div className="card mb-3 border-0 border-bottom rounded-0"
         style={{maxWidth: '540px'}}>
      <div className="row g-0">
        <div className="col-md-4 d-flex align-items-center">
          <img src={itemImage}
               className="img-fluid img-thumbnail"
               alt="..."/>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title"></h5>
            <p className="card-text">{item?.name}</p>
            <div className="card-text">
              <div className="input-group input-group-sm mb-3">
                <button className="btn btn-outline-secondary"
                        type="button"
                        onClick={onClickSub}
                        disabled={submitQtyToCart}>
                  <Spinner show={submitQtyToCart}/>
                  {
                    TrySync(() => {
                      if (!submitQtyToCart) {
                        return <i className="bi bi-dash-lg"></i>;
                      }
                      return <></>;
                    })
                  }
                </button>
                <input type="text"
                       value={qty}
                       onChange={onChangeQty}
                       className="form-control text-center"
                       placeholder=""
                       aria-label="Quantity"
                       disabled/>
                <button className="btn btn-outline-secondary"
                        onClick={onClickAdd}
                        type="button"
                        disabled={submitQtyToCart}>
                  <Spinner show={submitQtyToCart}/>
                  {
                    TrySync(() => {
                      if (!submitQtyToCart) {
                        return <i className="bi bi-plus-lg"></i>;
                      }
                      return <></>;
                    })
                  }
                </button>
                <button className="btn btn-outline-secondary d-none"
                        type="button"><i className="bi bi-trash-fill"></i>
                </button>
                <span className="input-group-text"
                      id="basic-addon2">{item?.amountFormatted}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemDrawer;
