import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutLayout from '../../layouts/checkout.layout';
import CartCheckout from '../../components/cart/checkout/cart-checkout';
import CheckoutBillingAddress
  from '../../components/checkout/checkout-billing-address';
import CheckoutPayment from '../../components/checkout/checkout-payment';
import CheckoutShipping from '../../components/checkout/checkout-shipping';
import { useListPaymentGateways }
  from '../../hooks/payment-gateways/use-list-payment-gateways.hook';
import { CheckoutPageContext } from './checkout-page.context';
import { Utils } from '../../utils';
import { AppContext, IAppContext } from '../../context';
import { Pages } from '../../utils/pages';

const CheckoutPage = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const listPaymentGateways = useListPaymentGateways();
  const [showThankYouMessage, setShowThankYouMessage] = useState(
    !!Utils.queryParam('paid'));
  const navigate = useNavigate();

  useEffect(() => {
    if (cart?.items?.length === 0) {
      navigate(Pages.home());
    }
  }, []);

  return (
    <CheckoutPageContext.Provider value={{
      listPaymentGateways,
      showThankYouMessage,
      setShowThankYouMessage,
    }}>
      <CheckoutLayout>
        <div className="container pt-5 pb-5 vh-100">
          <div className="row">
            <div className="col-md-4 order-md-2 mb-4">
              <CartCheckout/>
            </div>
            <div className="col-md-8 order-md-1">
              <CheckoutBillingAddress/>
              <hr className="mb-4"/>
              <CheckoutShipping/>
              <hr className="mb-4"/>
              <CheckoutPayment/>
            </div>
          </div>
        </div>
      </CheckoutLayout>
    </CheckoutPageContext.Provider>
  );
};

export default CheckoutPage;
