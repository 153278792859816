import { Expose } from "class-transformer";

export class CountryStateDto {
  @Expose()
  name?: string;

  @Expose()
  code?: string;

  @Expose()
  isoCode?: string;

  @Expose()
  i18n?: string;
}
