import React, { useState } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { AppContext, IAppContext } from './context';
import NotFoundPage from './pages/404/not-found.page';
import HomePage from './pages/home/home.page';
import CheckoutPage from './pages/checkout/checkout.page';
import OopsPage from './pages/oops/oops.page';

function App(props: IAppContext) {
  const [user, setUser] = useState(
    props.user as IAppContext);
  const [store, setStore] = useState(props.store);
  const [cart, setCart] = useState(props.cart);
  const context: IAppContext = {
    store,
    setStore,
    user,
    setUser,
    cart,
    setCart,
  };

  const router = createBrowserRouter([
    {
      path: '/store/:slug',
      element: <HomePage/>,
      errorElement: <OopsPage/>,
    },
    {
      path: '/store/:slug/checkout',
      element: <CheckoutPage/>,
      errorElement: <OopsPage/>,
    },
    {
      path: '*',
      element: <NotFoundPage/>,
      errorElement: <OopsPage/>,
    },
  ]);

  return (
    <AppContext.Provider value={context}>
      <RouterProvider router={router}/>
    </AppContext.Provider>
  );
}

export default App;
