import React, { useContext } from 'react';
import { AppContext, IAppContext } from '../../../context';
import CartItemCheckout from './cart-item-checkout';

const CartCheckout = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;

  return (
    <>
      <h4 className="d-flex justify-content-between align-items-center mb-3">
        <span className="text-muted">Your cart</span>
        <span className="badge badge-secondary badge-pill">{cart?.items?.length}</span>
      </h4>
      <ul className="list-group mb-3">
        {cart && cart?.items?.length >= 1 &&
          cart.items.map(item => <CartItemCheckout key={item.uuid}
                                                   item={item}/>)}
        <li className="list-group-item d-flex justify-content-between">
          <span>Total</span>
          <strong>{cart?.summary.totalFormatted ?? 0}</strong>
        </li>
      </ul>
    </>
  );
};

export default CartCheckout;