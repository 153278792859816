import React, { useContext, useEffect, useState } from 'react';
import { IProps } from '../../../types';
import { CartItemDto } from '../../../context/dto';
import { AppContext, IAppContext } from '../../../context';
import { CartUtils } from '../cart-utils';
import { ProductDto } from '../../products/dto/product-dto';

interface IItem extends IProps {
  item?: CartItemDto;
}

const CartItemCheckout = ({item}: IItem) => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const [itemImage, setItemImage] = useState('');
  const [qty, setQty] = useState(0);

  useEffect(() => {
    const [image] = item?.images?.filter(media => media.isMainImage) ?? [];
    setItemImage(
      image?.base64 ?? 'https://dummyimage.com/650x500/dee2e6/6c757d.jpg');
  }, [item]);

  useEffect(() => {
    setQty(item?.qty ?? 0);
  }, []);



  const onClickAdd = () => {
    const newQty = qty + 1;
    setQty(newQty);
    CartUtils.addToCart(cart, item as ProductDto, 1, setCart);
  };

  const onClickSub = () => {
    const newQty = qty - 1;
    if (newQty <= 1) {
      setQty(1);
      return;
    }
    setQty(qty - 1);
    CartUtils.addToCart(cart, item as ProductDto, -1, setCart);
  };

  const onChangeQty = (evt: any) => {
    // TODO: change qty via api
    setQty(evt.target.value);
  };
  
  return (
    <li className="list-group-item d-flex justify-content-between lh-condensed">
      <div>
        <h6 className="my-0">{item?.name}</h6>
        <small className="text-muted">{item?.shortDescription}</small>
      </div>
      <span className="text-muted">{item?.amountFormatted}</span>
    </li>
  )
};

export default CartItemCheckout;