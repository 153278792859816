import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CacheUtil, Pipes, RequestUtil } from '../../../../utils';
import { BillingAddressDto } from '../../../../context/dto';
import { PaypalOrderDto } from './dto/paypal-order.dto';
import { AppContext, IAppContext } from '../../../../context';
import {
  usePlaceOrderHook,
} from '../../../../hooks/checkout/use-place-order.hook';
import { PlaceOrderEnum } from '../../../../hooks/checkout/place-order.enum';
import {
  CheckoutPageContext,
} from '../../../../pages/checkout/checkout-page.context';
import {
  usePaymentGatewaysSettings,
} from '../../../../hooks/payment-gateways/use-payment-gateways-settings.hook';
import { PaypalPaymentGatewayEnum } from './enum/paypal.payment-gateway.enum';
import { PubSubEnum } from '../../../../enums/pub-sub.enum';
import { IUIToast } from '../../../toast/toast.ui';

const PaypalPaymentGateway = () => {
  const {cart, store} = useContext(AppContext) as IAppContext;
  const {setShowThankYouMessage} = useContext(CheckoutPageContext);
  const placeOrderFn = usePlaceOrderHook();
  const propertyClientId = usePaymentGatewaysSettings({
    paymentGatewayCode: 'PAYPAL',
    propertyCode: store.isSandbox
      ? PaypalPaymentGatewayEnum.PAYPAL_SANDBOX_CLIENT_ID
      : PaypalPaymentGatewayEnum.PAYPAL_CLIENT_ID,
  });
  const [paypalClientId, setPaypalClientId] = useState('');

  useEffect(() => {
    setPaypalClientId(propertyClientId.value ?? '');
  }, [propertyClientId]);

  const createOrder = useCallback(async () => {
    return placeOrderFn().then((response) => {
      if (response.status === PlaceOrderEnum.FAILED) {
        PubSub.publish(PubSubEnum.TOAST_ERROR,
          {message: 'Error creating Paypal order'} as IUIToast);
      }
      if (response.status === PlaceOrderEnum.SUCCESS) {
        // @ts-ignore
        CacheUtil.cache('__cart_order', response.order?.uuid);
        return response.order?.cartSnapshot?.payment?.reference;
      }
    });
  }, []);

  const approveOrder = useCallback(async () => {
    const billingAddress = Pipes.transform(BillingAddressDto,
      cart?.billingAddress);
    const cartToken = CacheUtil.cache('__cart');
    const order = CacheUtil.cache('__cart_order');
    RequestUtil.patch(
      `/checkout/cart/${cartToken}/order/${order}/payments/paypal/approved-order`,
      {billingAddress},
      {},
      () => PaypalOrderDto,
    ).then(order => {
      setShowThankYouMessage && setShowThankYouMessage(true);
    }).catch(error => {
      console.error(error);
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {message: 'Error checking Paypal order'} as IUIToast);
    });
  }, []);

  return (
    paypalClientId ? (
      <PayPalScriptProvider options={{
        clientId: paypalClientId,
        components: 'buttons',
        intent: 'capture',
        vault: false,
      }}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={approveOrder}
        />
      </PayPalScriptProvider>
    ) : <></>

  );
};

export default PaypalPaymentGateway;