import PupSub from 'pubsub-js';
import { IProps } from '../../types';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { PubSubEnum } from '../../enums/pub-sub.enum';

export interface IUIToast extends IProps {
  topic: PubSubEnum[];
  message?: ReactNode;
}

const UIToast = ({topic}: IUIToast) => {
  const $ref = useRef(null);
  const [toastClassName, setToastClassName] = useState(
    'toast w-auto text-white border-0 ms-auto z-index-3 fixed-top start-auto mt-1 me-1 mt-sm-3 me-sm-3');
  const [bodyMessage, setBodyMessage] = useState(<></> as ReactNode);

  // Subscriber
  useEffect(() => {
    if (!$ref) {
      return;
    }
    const $component = ($ref.current as any);
    const $toast = bootstrap.Toast.getOrCreateInstance($component);

    if (Array.isArray(topic)) {

      if (topic.includes(PubSubEnum.TOAST_ERROR)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_ERROR);
        PupSub.subscribe(PubSubEnum.TOAST_ERROR, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-danger`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-x-octagon fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }

      if (topic.includes(PubSubEnum.TOAST_SAVED)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_SAVED);
        PupSub.subscribe(PubSubEnum.TOAST_SAVED, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-success`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-check-circle fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }

      if (topic.includes(PubSubEnum.TOAST_SUCCESS)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_SUCCESS);
        PupSub.subscribe(PubSubEnum.TOAST_SUCCESS, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-success`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-check-circle fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }

      if (topic.includes(PubSubEnum.TOAST_WARNING)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_WARNING);
        PupSub.subscribe(PubSubEnum.TOAST_WARNING, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-warning`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-check-circle fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }

      if (topic.includes(PubSubEnum.TOAST_UPDATE)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_UPDATE);
        PupSub.subscribe(PubSubEnum.TOAST_UPDATE, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-success`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-check-circle fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }

      if (topic.includes(PubSubEnum.TOAST_DELETE)) {
        PupSub.unsubscribe(PubSubEnum.TOAST_DELETE);
        PupSub.subscribe(PubSubEnum.TOAST_DELETE, (topic, data: IUIToast) => {
          setToastClassName(`${toastClassName} bg-success`);
          setBodyMessage(<div className={'d-flex px-1 align-items-center'}>
            <i className="bi bi-check-circle fs-3"></i>
            <strong className={'align-middle ms-3'}>{data.message}</strong>
          </div>);
          setTimeout(() => {
            $toast && $toast.show && $toast.show();
          }, 0);
        });
      }
    }
  }, []);

  return <div ref={$ref}
              className={toastClassName}
              role="alert"
              aria-live="assertive"
              aria-atomic="true">
    <div className="d-flex">
      <div className="toast-body py-1">
        {bodyMessage}
      </div>
      <button type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"></button>
    </div>
  </div>;
};

export { UIToast };