import { Utils } from './utils';
import { PagesEnum } from '../enums/pages.enum';

export class Pages {
  static home() {
    return Utils.basePath();
  }

  static about() {
    return Utils.basePath() + PagesEnum.ABOUT;
  }
  
  static checkout() {
    return Utils.basePath() + PagesEnum.CHECKOUT;
  }
}