import React, { ReactNode } from 'react';
import { IProps } from '../../types';

export interface IThankYouMsg extends IProps {
  message: ReactNode;
}

const CheckoutThankYouMsg = ({message, className, children}: IThankYouMsg) => {
  return (
    <>
      <div
        className={className ? className : 'position-fixed w-100 h-100 d-flex top-0 start-0 flex-column text-white text-center justify-content-center align-items-center bg-primary z-index-1'}>
        <i className="bi bi-check-circle text-white fs-5rem"></i>
        <h2 className="mt-5 ps-5 pe-5">{message}</h2>
        {children}
      </div>
    </>
  );
};

export default CheckoutThankYouMsg;
