import PupSub from 'pubsub-js';
import {
  CheckoutTopicsConstants,
} from '../../components/checkout/checkout-topics-constants';
import { useCallback, useContext } from 'react';
import { CacheUtil, Pipes, RequestUtil } from '../../utils';
import { BillingAddressDto } from '../../context/dto';
import { AppContext, IAppContext } from '../../context';
import { ShippingMethodDto } from '../shipping-methods/dto/shipping-method.dto';
import { PaymentGatewayDto } from '../payment-gateways/dto/payment-gateway.dto';
import { PlaceOrderEnum } from './place-order.enum';
import { OrderDto } from './dto/order.dto';
import { ResponsePlaceOrderDto } from './dto/response-place-order.dto';
import { PubSubEnum } from '../../enums/pub-sub.enum';
import { IUIToast } from '../../components/toast/toast.ui';

function usePlaceOrderHook() {
  const {cart, setCart} = useContext(AppContext) as IAppContext;

  return useCallback(async (): Promise<ResponsePlaceOrderDto> => {
    PupSub.publish(
      CheckoutTopicsConstants.CHECKOUT_PAYMENT_CHECK_BILLING_ADDRESS_FORM, {});
    await Pipes.validation(BillingAddressDto, cart?.billingAddress || {}).
      catch(errors => {
        console.error(errors);
        throw errors;
      });
    const billingAddress = Pipes.transform(BillingAddressDto,
      cart?.billingAddress);

    await Pipes.validation(ShippingMethodDto, cart?.shipping || {}).catch(errors => {
      console.error(errors);
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {message: 'Error in Shipping Method'} as IUIToast);
      throw errors;
    });
    const shippingMethod = Pipes.transform(ShippingMethodDto,
      {
        uuid: cart?.shipping?.uuid
      });

    await Pipes.validation(PaymentGatewayDto, cart?.payment).catch(errors => {
      console.error(errors);
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {message: 'Error in Payment Gateway'} as IUIToast);
      throw errors;
    });
    const paymentGatewayChoose = Pipes.transform(PaymentGatewayDto,
      cart?.payment);

    const cartToken = CacheUtil.cache('__cart');
    return await RequestUtil.post(
      `/checkout/cart/${cartToken}/payments/create-order`,
      {billingAddress, shippingMethod, paymentGateway: paymentGatewayChoose},
      {},
      () => OrderDto,
    ).then(order => {
      return {
        status: PlaceOrderEnum.SUCCESS,
        order,
      };
    }).catch(errors => {
      console.error(errors);
      return {
        status: PlaceOrderEnum.FAILED,
        errors,
      };
    });
  }, [cart]);
}

export { usePlaceOrderHook };