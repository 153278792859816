import { IsNotEmpty, IsString } from "class-validator";

export class StoreCmsMediaDto {
  @IsString()
  @IsNotEmpty()
  name!: string;

  @IsString()
  @IsNotEmpty()
  type!: string;

  @IsString()
  @IsNotEmpty()
  base64!: string;
}
