import { useContext, useEffect, useState } from 'react';
import {
  CheckoutPageContext,
} from '../../pages/checkout/checkout-page.context';
import { PaymentGatewayPropertyDto } from './dto/payment-gateway-property.dto';
import { PaymentGatewayDto } from './dto/payment-gateway.dto';

export interface IUsePaymentGatewaySettingsProps {
  paymentGatewayCode: string;
  propertyCode: string;
}

function usePaymentGatewaysSettings(props: IUsePaymentGatewaySettingsProps) {
  const [property, setProperty] = useState({} as PaymentGatewayPropertyDto);
  const {listPaymentGateways} = useContext(CheckoutPageContext);
  useEffect(() => {
    const {properties} = listPaymentGateways?.find(
      paymentGateway => paymentGateway.code === props.paymentGatewayCode) ??
    {} as PaymentGatewayDto;
    const propertyFound = properties?.find(
        property => property.property === props.propertyCode) ??
      {} as PaymentGatewayPropertyDto;
    if (propertyFound) {
      setProperty(propertyFound);
    }
  }, [listPaymentGateways]);
  return property;
};

export { usePaymentGatewaysSettings };