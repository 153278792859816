import { Expose, Transform } from 'class-transformer';
import { StoreCmsPropertiesDto } from './store-cms-properties.dto';
import { CmsUtils } from '../../utils/cms-utils';
import { CmsConstants } from '../../enums/cms-constants';

export class StoreDto {
  @Expose()
  uuid!: string;

  @Expose()
  @Transform(({obj: data}) => {
    return !data.isLive;
  })
  isSandbox!: boolean;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_NAVBAR_BRAND_NAME);
  })
  navbarBrandName!: string;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_NAVBAR_BRAND_MEDIA);
  })
  navbarBrandMedia!: string;
  
  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_HERO_STORE_TITLE);
  })
  title!: string;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_HERO_STORE_SUBTITLE);
  })
  subTitle!: string;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_HERO_STORE_BG_COLOR);
  })
  heroBgColor!: string;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_HERO_STORE_MEDIA);
  })
  heroBgMedia!: string;

  @Expose()
  @Transform(({obj: data}: {obj: StoreCmsPropertiesDto}) => {
    return CmsUtils.propertyValue(data, CmsConstants.PROPERTY_HERO_STORE_MEDIA_MOBILE);
  })
  heroBgMediaMobile!: string;
}
