import React from 'react';

const MainFooter = () => {
  return (
    <>
      <footer className="py-4 bg-dark">
        <div className="container">
          <p className="m-0 text-center text-white fs-7">&copy; 2024, Snoween.net,
            All Rights Reserved.
          </p></div>
      </footer>
    </>
  );
};

export { MainFooter };
