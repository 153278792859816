import { Expose } from 'class-transformer';

export class CountryDto {
  @Expose()
  name?: string;

  @Expose()
  code?: string;

  @Expose()
  isoCode?: string;

  @Expose()
  isoCode3?: string;

  @Expose()
  isoNumCode3?: string;

  @Expose()
  i18n?: string;
}
