import { Expose, Transform, Type } from 'class-transformer';
import { Pipes, Utils } from '../../utils';
import { ProductMediaDto } from '../../components/products/dto/product-media-dto';

export class CartItemDto {
  @Expose()
  uuid!: number;

  @Expose()
  name!: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  qty!: number;
  
  @Expose()
  price?: number;
  
  @Expose()
  mutedPrice?: number;

  @Expose()
  amount?: number;
  
  @Expose()
  @Transform(({obj: product}: { obj: CartItemDto }) => {
    return Utils.formatNumber(product.price ?? 0);
  })
  priceFormatted?: string;

  @Expose()
  @Transform(({obj: product}: { obj: CartItemDto }) => {
    return Utils.formatNumber(product.mutedPrice ?? 0);
  })
  mutedPriceFormatted?: string;

  @Expose()
  @Transform(({obj: product}: { obj: CartItemDto }) => {
    return Utils.formatNumber(product.amount ?? 0);
  })
  amountFormatted?: string;
  
  @Expose()
  @Transform(({obj: product}: { obj: CartItemDto }) => {
    return product?.images?.map(
      image => Pipes.transform(ProductMediaDto, image)) ?? [];
  })
  images!: ProductMediaDto[];
}
