import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Utils } from '../../utils';
import CheckoutThankYouMsg from './checkout-thank-you-msg';
import { NavLink } from 'react-router-dom';
import { AppContext, IAppContext } from '../../context';
import Spinner from '../spinner/spinner';
import { usePlaceOrderHook } from '../../hooks/checkout/use-place-order.hook';
import { PlaceOrderEnum } from '../../hooks/checkout/place-order.enum';
import PaypalPaymentGateway
  from './payment-gateway/paypal/paypal.payment-gateway';
import {
  CheckoutPageContext,
} from '../../pages/checkout/checkout-page.context';
import { PubSubEnum } from '../../enums/pub-sub.enum';
import { IUIToast } from '../toast/toast.ui';
import { PaymentGatewayEnum } from '../../enums/payment-gateway.enum';

const CheckoutPayment = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const {
    listPaymentGateways,
    showThankYouMessage,
    setShowThankYouMessage,
  } = useContext(CheckoutPageContext);
  const [code, setCode] = useState('');
  const [submitPlaceOrder, setSubmitPlaceOrder] = useState(false);

  const whichPaymentGateway = useCallback(() => {
    const currentPaymentGateway = listPaymentGateways?.find(
      paymentGateway => paymentGateway.code === code);
    if (!currentPaymentGateway) {
      const [defaultPaymentGateway] = listPaymentGateways || [];
      return defaultPaymentGateway;
    }
    return currentPaymentGateway;
  }, [listPaymentGateways, code]);

  useEffect(() => {
    const paymentGateway = whichPaymentGateway();
    if (cart) {
      cart.payment = {
        uuid: paymentGateway?.uuid,
      };
      setCart && setCart(cart);
    }
  }, [listPaymentGateways, code]);

  const onChangePaymentGateway = useCallback((evt: any) => {
    setCode(evt.target.value);
  }, []);

  const placeOrderFn = usePlaceOrderHook();
  const placeOrder = useCallback(() => {
    setSubmitPlaceOrder(true);
    placeOrderFn().then((response) => {
      if (response.status === PlaceOrderEnum.FAILED) {
        PubSub.publish(PubSubEnum.TOAST_ERROR,
          {message: 'Error creating order'} as IUIToast);
      }
      if (response.status === PlaceOrderEnum.SUCCESS) {
        if (response.order?.cartSnapshot?.payment?.paymentLink) {
          window.location.href = response.order?.cartSnapshot?.payment?.paymentLink;
        } else {
          PubSub.publish(PubSubEnum.TOAST_ERROR,
            {message: 'Error creating order'} as IUIToast);
        }
        return;
      }
    }).catch(() => {
      PubSub.publish(PubSubEnum.TOAST_ERROR,
        {message: 'Error creating order'} as IUIToast);
    }).finally(() => {
      setSubmitPlaceOrder(false);
    });
  }, [cart]);

  const whichPlaceOrderButton = useCallback(() => {
    switch (whichPaymentGateway()?.code) {
      case PaymentGatewayEnum.STRIPE:
        return <div className="d-grid gap-2 mb-4">
          <button className="btn btn-primary btn-lg"
                  type="button"
                  onClick={placeOrder}
                  disabled={submitPlaceOrder}>
            <Spinner show={submitPlaceOrder}/>
            {
              !submitPlaceOrder ? 'Place order' : <></>
            }
          </button>
        </div>;
      case PaymentGatewayEnum.PAYPAL:
        return <PaypalPaymentGateway/>;
      default:
        return <></>;
    }
  }, [listPaymentGateways, code, submitPlaceOrder, cart]);

  return (
    <>
      <CheckoutThankYouMsg
        message={<><p>Payment Success!!</p><p>We've sent an email</p></>}
        className={!showThankYouMessage ? 'd-none' : ''}>
        <NavLink to={''}
                 onClick={() => window.location.href = Utils.basePath()}
                 className={'text-white mt-4'}>Go to Store</NavLink>
      </CheckoutThankYouMsg>
      {(listPaymentGateways?.length ?? 0) > 0 &&
          <>
            {
              (listPaymentGateways?.length ?? 0) > 1 &&
                <h4 className="mb-3">Choose Your Payment Method</h4>
            }
            <div className="d-block my-3">
              {
                (listPaymentGateways?.length ?? 0) > 1 &&
                listPaymentGateways?.map(
                  (paymentGateway, index) => (
                    <div key={paymentGateway.uuid}
                         className="form-check">
                      <input id={paymentGateway.uuid}
                             name="paymentMethod"
                             type="radio"
                             className="form-check-input"
                             value={paymentGateway.code}
                             onChange={onChangePaymentGateway}
                             defaultChecked={index === 0}/>
                      <label className="form-check-label"
                             htmlFor={paymentGateway.uuid}>{paymentGateway.name}</label>
                    </div>
                  ))
              }
              {
                (listPaymentGateways?.length ?? 0) === 0 &&
                  <>Not Payment Method available!</>
              }
            </div>
            {
              (listPaymentGateways?.length ?? 0) > 1 &&
                <hr className="mb-4"/>
            }
          </>
      }
      {whichPlaceOrderButton()}
    </>
  );
};

export default CheckoutPayment;
