import React, { useContext, useEffect, useState } from 'react';
import { AppContext, IAppContext } from '../context';
import { MainContainer } from '../components/main/main-container';
import { MainNavbar } from '../components/main/main-navbar';
import { IProps } from '../types';
import Hero from '../components/hero/hero';
import CartDrawer from '../components/cart/drawer/cart-drawer';
import { MainFooter } from '../components/main/main-footer';
import { UIToast } from '../components/toast/toast.ui';
import { PubSubEnum } from '../enums/pub-sub.enum';

const MainLayout = ({children}: IProps) => {
  const {user} = useContext(AppContext) as IAppContext;
  const [avatarImage, setAvatarImage] = useState();

  useEffect(() => {
    setAvatarImage(user?.avatarMedia?.base64 ?? '');
  }, [user]);

  return (
    <div className={'d-flex flex-column h-100'}>
      <UIToast topic={Object.values(JSON.parse(JSON.stringify(PubSubEnum)))}></UIToast>
      <MainNavbar avatarImage={avatarImage}/>
      <header>
        <Hero></Hero>
      </header>
      <main className="bg-light">
        <MainContainer>
          {children}
        </MainContainer>
        <CartDrawer />
      </main>
      <MainFooter/>
    </div>

  );
};

export default MainLayout;
