import React, { useContext } from 'react';
import { AppContext, IAppContext } from '../../../context';
import CartItemDrawer from './cart-item-drawer';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../utils/pages';

const CartDrawer = () => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const navigate = useNavigate();

  return (
    <div className="offcanvas offcanvas-end"
         data-bs-scroll="true"
         tabIndex={-1}
         id="offcanvasRight"
         aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Cart</h5>
        <button type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
      </div>
      <div className="offcanvas-body px-4 px-md-3">
        {cart && cart?.items?.length >= 1 &&
          cart.items.map(item => <CartItemDrawer key={item.uuid}
                                                 item={item}/>)}
      </div>
      <div className="container pt-4 pb-4 border-top shadow">
        <div className="d-flex justify-content-between pb-2">
          <span>Subtotal</span>
          <span className="text-muted">{cart?.summary.subtotalFormatted ??
            0}</span>
        </div>
        <div className="d-flex justify-content-between pb-3">
          <span>Total</span>
          <span className="fs-4">{cart?.summary.totalFormatted ?? 0}</span>
        </div>
        <div>
          <button className="btn btn-primary btn-lg w-100"
                  onClick={() => navigate(Pages.checkout())}
                  disabled={cart?.items?.length === 0}
          >Checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartDrawer;
