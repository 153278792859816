import { useEffect, useState } from 'react';
import { RequestUtil } from '../../utils';
import { CountryDto } from './dto/country.dto';

export default function useListCountries() {
  const [countries, setCountries] = useState([] as Array<CountryDto>);
  useEffect(() => {
    RequestUtil.get('/countries', {}, () => CountryDto).then(response => {
      setCountries(response as Array<CountryDto>);
    }).catch(errors => console.error('Error: useListCountries'));
  }, []);
  return countries;
};