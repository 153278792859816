import React, { useCallback, useEffect, useState } from 'react';
import HomeProductItem from './home-product-item';
import { RequestUtil } from '../../../utils';
import { ProductDto } from '../dto/product-dto';

const HomeProductsContainer = () => {
  const [products, setProducts] = useState([] as ProductDto[])
  const callback = useCallback(() => {
    RequestUtil.get(`/products`, {}, () => ProductDto)
      .then(response => {
        setProducts(response as unknown as ProductDto[])
    });
  }, []);

  useEffect(() => {
    callback();
  }, []);

  return (
    <section className="py-0 py-lg-5">
      <div className="container px-4 px-lg-5 mt-4 mt-lg-5">
        <div
          className="row gx-4 gx-lg-5 row-cols-1 row-cols-md-3 row-cols-xl-4 justify-content-center">
          {products.length >= 1 &&
            products.map(product => <HomeProductItem key={product.uuid} product={product}/>)}
        </div>
      </div>
    </section>
  );
};

export { HomeProductsContainer as default };
